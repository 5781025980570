<template>
    <div class="acms-v-select" :data-v-select-settings="[partialTemplates.templateName.toggle]"
         v-click-outside="hideListHandle"
         :style="style"
         :class="{'acms-v-select--disabled': disabled}"
    >
        <div class="acms-v-select-toggle " @click="toggleListHandle"
             :class="{'open': locState.listShow}"
             ref="toggleElRef"
        >

            <component :is="partialTemplates.toggle"
                       @removeTag="removeSelectedTagHandle"
                       v-bind="getToggleProps">
            </component>
            <div class="acms-v-select-toggle-right">
                <div class="acms-v-select-toggle-angle"></div>
                <slot name="toggleRight"></slot>
            </div>
        </div>
        <div class="acms-v-select-dropdown" v-if="locState.listShow"
             ref="listElRef">
            <template v-if="search">
                <div class="acms-v-select-search ">
                    <div class="position-relative">
                        <input type="text" class="form-control  "
                               v-model="locState.search"
                               :placeholder="searchPlaceholder ?? getPlaceholder"
                               ref="searchInputElRef"
                               autofocus

                               @keydown="searchInputKeyHandle"
                        >
                        <acms-v-icon name="search"/>
                    </div>

                </div>
            </template>
            <slot name="dropdownTop"></slot>
            <template v-if="locState.searchHasErrors">
                <div class="acms-v-select-search-text">
                    No result
                </div>
            </template>
            <template v-else-if="locState.searchIsProcessing">
                <div class="acms-v-select-search-text">
                    Searching...
                </div>
            </template>
            <template v-else-if="getOptions?.length > 0">

                <template v-if="optionsListHasGroups">
                    <div class="acms-v-select-options-list with-groups">
                        <template v-for="(group, groupIndex) in getOptions">

                            <template v-if="group?.[optionsListGroupItemsKey]?.length > 0">
                                <div class="acms-v-select-options-list-group-title">{{group?.[optionsListGroupTitleKey]}}</div>

                                <template v-for="(option, optionIndex) in group?.[optionsListGroupItemsKey]">

                                    <template v-if="partialTemplates.option">

                                        <component :is="partialTemplates.option"
                                                   :option="option"
                                                   :optionIndex="optionIndex"
                                                   v-bind="getOptionProps"
                                                   @onSelect="selectOptionHandle"
                                                   :focusedOption="locState.focusedOption"
                                        ></component>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </div>
                </template>
                <div v-else class="acms-v-select-options-list">
                    <template v-for="(option, optionIndex) in getOptions">
                        <template v-if="partialTemplates.option">
                            <component :is="partialTemplates.option"
                                       :option="option"
                                       :optionIndex="optionIndex"
                                       v-bind="getOptionProps"
                                       @onSelect="selectOptionHandle"
                                       :focusedOption="locState.focusedOption"
                            ></component>
                        </template>
                        <template v-else>
                            <div class="acms-v-select-option acms-v-select-option--default acms-v-select-option-hover"
                                 @click="()=>selectOptionHandle(option)">

                                {{ option[optionKeyTitle] }}
                            </div>
                        </template>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="acms-v-select-options-list-empty ">
                    <template
                        v-if="getOptions?.length  == 0 && !locState.searchIsProcessing && search && searchAsync && locState.searchStringOld">
                        No results for <b>{{ locState.searchStringOld }}</b>, try again
                    </template>
                    <template v-else-if="!locState.searchIsProcessing && search && searchAsync">
                        Еnter min {{ minSymbolsForSearch }} characters to search
                    </template>
                    <template v-else>
                        Options list is empty
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AcmsVSelect',
};
</script>
<script setup>
import {onMounted, provide,} from 'vue';
import {defaultAttributesProps} from '@components/ui/FormField/field.props';
import selectComputed from './select.computed';
import {selectProps} from './select.props';
import selectComponent from './select.component';

const emit = defineEmits(['update:modelValue', 'select', 'removeTag']);
const props = defineProps({
    ...defaultAttributesProps,
    ...selectProps,
});

const {
    setTemplateForPartials, selectOptionHandle,
    removeSelectedTagHandle, toggleListHandle, hideListHandle,
    searchInputKeyHandle,
    locState,
    toggleElRef, listElRef, searchInputElRef,

} = selectComponent({props, emit});

provide('selectLocState', locState)

const {
    getToggleProps,
    getOptionProps,
    getOptions,
    getPlaceholder,

} = selectComputed({props, locState});




const partialTemplates = setTemplateForPartials?.();
</script>
<style lang="scss">
@import "style";
[data-v-select-settings="dropdown"] {
    width: 200px;
    .acms-v-select-toggle {
        border-radius: 4px;
    }
    .acms-v-select-option-inner {
        padding-left: 28px;
        font-size: 14px;
        line-height: 20px;
        &.selected {
            color: #4C78DD;
            background-position: 12px;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0295 2.47021C11.3225 2.76318 11.3225 3.23896 11.0295 3.53193L5.02949 9.53193C4.73652 9.8249 4.26074 9.8249 3.96777 9.53193L0.967773 6.53193C0.674805 6.23897 0.674805 5.76318 0.967773 5.47021C1.26074 5.17725 1.73652 5.17725 2.02949 5.47021L4.4998 7.93818L9.97012 2.47021C10.2631 2.17725 10.7389 2.17725 11.0318 2.47021H11.0295Z' fill='%234C78DD'/%3E%3Cpath d='M11.0295 2.47021C11.3225 2.76318 11.3225 3.23896 11.0295 3.53193L5.02949 9.53193C4.73652 9.8249 4.26074 9.8249 3.96777 9.53193L0.967773 6.53193C0.674805 6.23897 0.674805 5.76318 0.967773 5.47021C1.26074 5.17725 1.73652 5.17725 2.02949 5.47021L4.4998 7.93818L9.97012 2.47021C10.2631 2.17725 10.7389 2.17725 11.0318 2.47021H11.0295Z' fill='black' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            pointer-events: none;
        }
    }
}
[data-v-select-settings="cityAirport"] {
    .acms-v-select-dropdown {
        width: 480px !important;
        left: 0;
    }
}
[data-v-select-settings="withCode"] {
    .acms-v-select-dropdown {
        min-width: 170px;
    }
}
</style>
