const getRouteInGroup = ( name, params, group = 'dashboard') => {
    const routes = window.__AppRoutes?.[group];
    if (routes && name in routes) {
        if (Array.isArray(params)) {
            const [value, key = 'id'] = params;
            const link = routes[name].replace(`{${key}}`, value);
            return link;
        }
        else if (typeof  params == "number" || typeof  params == "string"){
            return routes[name].replace(`{id}`, params);
        }

        else if (typeof params == 'object') {
            let link = routes[name];

            Object.entries(params).forEach(([key, value]) => {
                link = link.replace(`{${key}}`, value);
            });
            console.log('ling', link);
            return link;
        } else {
            return routes[name];
        }
    }
}

export const getRoute = (name = 'index', params = undefined, group = undefined) => {
    if (name === 'unifire') {
        return `/admin/token`;
    }
    if(!group){

        return getRouteInGroup(name, params)
    }
    else{

        return getRouteInGroup(name, params, group)
    }
};

export const pluginGetRoute = () => {
    return {
        install: (v, options) => {
            v.mixin({
                methods: {
                    getRoute,
                },
            });
            if (parseInt(v.version) > 2) {
                v.provide('getRoute', getRoute);
            }
        },
    };

};





