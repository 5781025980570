<template>
    <Head title="Create new client - PrivyTrips"></Head>
    <AppMainLayout
        title="Create new client"
        :breadcrumbs="[{title: 'Clients', link: getRoute('clients')}, 'Create new client']"
    >
        <template #content>
            <div class="page-create-client">
                <div class="row g-4">

                    <div class="col-3 page-inner-sidebar" >
                            <acms-v-card  title="Client details">
                                <div class="new-client-names mb-4">
                                    <BoxNames
                                        v-model="locState.clientDetailsForm.names"
                                        :errors="locState.clientDetailsForm.errors"
                                    />
                                </div>
                                <div class="new-client-emails mb-4">
                                    <BoxEmailsList
                                        v-model="locState.clientDetailsForm.emails"
                                        :errors="locState.clientDetailsForm.errors"
                                        :updatedData="locState.client?.email"
                                    />
                                </div>
                                <BoxPhoneNumbersAndBestTimeToCall
                                    v-model:phone-numbers="locState.clientDetailsForm.phone_numbers"
                                    :updated-phone-numbers="locState.client?.phone"
                                    v-model:best-time-to-call="locState.clientDetailsForm.best_time_to_call"
                                    :errors="locState.clientDetailsForm.errors"
                                />
                                <div class="new-client-gender-n-contact mb-3">
                                    <div class="row g-3">
                                        <FieldSelectGender
                                            class="col-6"
                                            v-model="locState.clientDetailsForm.gender_id"
                                            :errors="locState.clientDetailsForm.errors"
                                        />
                                        <FieldSelectContactBy
                                            class="col-6"
                                            :errors="locState.clientDetailsForm.errors"
                                            v-model="locState.clientDetailsForm.contact_by_id"
                                        />
                                    </div>
                                    <acms-v-btn v-if="typeof locState.clientDetailsForm.referral_id != 'string'"
                                                title="Add referal ID"
                                                preset="simple-primary"
                                                icon="plus"
                                                @click="locState.clientDetailsForm.referral_id = ''"
                                    />
                                </div>
                                <div class="new-client-referral mb-3">
                                    <template v-if="locState.clientDetailsForm.referral_id != null">
                                        <acms-v-label label="Referral ID"/>
                                        <div class="row">
                                            <acms-v-form-field
                                                class="col-10"
                                                placeholder="123123345"
                                                modeInput
                                                name="referral_id"
                                                v-model="locState.clientDetailsForm.referral_id"
                                                :errors="locState.clientDetailsForm.errors"
                                            />
                                            <div class="col-2">
                                                <acms-v-btn
                                                    icon="close"
                                                    class="bg-gray-light align-self-end btn-row-action"
                                                    width="38"
                                                    @click="removeReferralHandle"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <acms-v-form-field
                                    label="General notes about client"
                                    :modeTextarea="{resize:true, minHeight:96}"
                                    placeholder="Placeholder"
                                    v-model="locState.clientDetailsForm.notes"
                                />
                            </acms-v-card>
                    </div>

                    <div class="col-9 page-inner-content">
                        <BoxClientRequestsAndLinkedContacts
                            linkedContactsEmpty
                            :mainClientId="locState.client?.id"
                            parentControl
                            :canSaveRequestsAndLinkedContacts="locState.canSaveRequestsAndLinkedContacts"
                            :canSaveLinkedContacts="locState.canSaveLinkedContacts"
                            @save="checkToRedirectPage"
                        />
                    </div>
                </div>
                <AcmsVModalAlert
                    v-model:show="locState.saveAndExitModalAlert"
                    :title="locState.saveModalAlertContent?.title ?? ''"
                    :text="locState.saveModalAlertContent?.content ?? ''"
                    @left-button-click="locState.saveAndExitModalAlert = false"
                    @rightButtonClick="()=>saveAndGoHandle(true)"
                    left-button-title="Continue editing"
                    right-button-title="Yes, save"
                />
                <AcmsVModalAlert
                    v-model:show="locState.saveModalAlert"
                    :title="locState.saveModalAlertContent?.title ?? ''"
                    :text="locState.saveModalAlertContent?.content ?? ''"
                    @left-button-click="locState.saveModalAlert = false"
                    @rightButtonClick="()=>saveAndGoHandle()"
                    left-button-title="Continue editing"
                    right-button-title="Yes, save"
                />
                <acms-v-bottom-actions
                    alertForCancel
                    @modalAlertCancel="router.get(getRoute('clients'))"
                    modal-alert-cancel-type="pageClientCreate"
                    @save="saveHandle()"
                    @saveAndExit="saveHandle(true)"
                    :disabledSave="submitProcessing"
                />
            </div>
        </template>
</AppMainLayout>

</template>

<script>
export default {
    name: 'CreateClientPage',
};
</script>

<script setup="">
import {Head, router} from "@inertiajs/vue3";
import useClientCreateComponent from "./composables/useClientCreate.component";
import { BoxEmailsList, BoxNames} from '@boxes/common';
import BoxClientRequestsAndLinkedContacts from '@boxes/client/BoxClientRequestsAndLinkedContacts/BoxClientRequestsAndLinkedContacts.vue';
import AppMainLayout from '@layouts/AppMainLayout.vue';
import {getRoute} from "@plugins/useRoutes";
import FieldSelectContactBy from "@components/app/fields/FieldSelectContactBy.vue";
import FieldSelectGender from "@components/app/fields/FieldSelectGender.vue";
import AcmsVModalAlert from "@ui/ModalAlert.vue";
import BoxPhoneNumbersAndBestTimeToCall from "../../boxes/common/BoxPhoneNumbersAndBestTimeToCall.vue";

const props = defineProps({airports: Array});

const {
    saveHandle,saveAndGoHandle,
    submitProcessing,
    locState,
    checkToRedirectPage,
    removeReferralHandle,
} = useClientCreateComponent({props})




</script>

<style lang="scss">
.card-requests {
    .block-content {
        padding: 0;
    }
}
</style>
