<template>
    <acms-v-dropdown
        :options="getOptions"
        title="More"
        toggleClass="btn-sm btn-border"
        hideAngle
        placement="left"
        optionClass="ps-3"
        @select="$emit('select', $event)"
    />
</template>

<script>
export default {
    name: "ElementDropdownMoreOptions"
}
</script>

<script setup="">

import {PQ_ROW_MORE_OPTIONS} from "../constants";
import {computed} from "vue";
import {cloneDeep} from "lodash";

const emit = defineEmits(['select', ''])
const props = defineProps({
    additionalOption: String,
    pqHiddenStatus:Boolean,
})



const getOptions = computed(() => {
    const optionsObject = cloneDeep(PQ_ROW_MORE_OPTIONS)

    optionsObject.hidePQ = props.pqHiddenStatus ? 'Unhide PQ' : "Hide PQ";

    return [...Object.values(optionsObject), props.additionalOption]
})

</script>
<style scoped>

</style>
