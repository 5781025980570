<template>
    <Head title="Requests - PrivyTrips"></Head>
    <AppMainLayout title="Requests">
        <template #content>
            <div class="page-requests block block-rounded">
                <div class="block-content block-content-full">
                    <BoxTableRequests
                        :collection="collection"
                        hideAddBtn
                        v-model:page="tableForm.page"
                        v-model:entries="tableForm.limit"
                        v-model:search="tableForm.search"
                        @sortChange="tableSortHandle"
                        emptyStateTitle="Requests"
                        :agents="agents"
                        @update:selectedRows="updateSelectedRows"
                        @handle:assignAgent="handleAssignAgent"
                        :hideAssignAgentBtn="!backendPermissions.allowAssignAgents"
                    />
                </div>
            </div>
        </template>
    </AppMainLayout>
</template>

<script>
import axios from 'axios';
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";

export default {
    name: 'RequestsPage',
    data() {
        return {
            selectedRows: [],
        };
    },
    methods: {
        updateSelectedRows(newSelectedRows) {
            this.selectedRows = newSelectedRows;
        },
        handleAssignAgent(data) {
            const requestIds = this.selectedRows.map(row => row.id);

            console.log('Selected Request IDs:', requestIds);
            axios.post('requests/assign-agent', {
                agent_ids: data.agent,
                request_ids: requestIds
            })
                .then(response => {
                    console.log('Success:', response.data.message);
                    const AlertsManager = useAlertsManagerStore();
                    AlertsManager.add('Agent successfully assigned to Requests');
                    this.$emit('agent-assigned', response.data.requests);
                })
                .catch(error => {
                    console.error('Error:', error);
                    const AlertsManager = useAlertsManagerStore();
                    AlertsManager.add('Please Select Requests and/or Agents', 'danger');
                });
        }
    }
};
</script>

<script setup>
import {computed} from 'vue';
import BoxTableRequests from '@boxes/request/BoxTableRequests.vue';
import AppMainLayout from "@layouts/AppMainLayout.vue";
import {Head} from '@inertiajs/vue3';
import {getRoute} from "@plugins/useRoutes";
import useTableSortable from "@ui/Table/useTableSortable";
import {useBackendMiddlewarePropsService} from "@services";
const {backendPermissions} = useBackendMiddlewarePropsService()
const props = defineProps({collection: Object, tableParams: Object, agents: Array});
console.log('props', props.collection);
const {tableForm, tableSortHandle} = useTableSortable({
    route: getRoute('requests'),
    sendData: {
        limit: props.tableParams?.limit ?? 20,
        search: props.tableParams?.search ?? '',
    }
});
</script>

<style scoped>
/* Your styles here */
</style>
