<template>
    <div class="box-price-quotes w-100">
<!--        <template v-if="locState.canShowHeadButtons">-->
<!--            <teleport to="#request-page-head-tab-pq">-->
<!--                <div class="d-flex">-->
<!--                    <ElementDropdownAddPq v-if="backendPermissions?.allowCreateRequestPQ" />-->
<!--                    <ElementSendPqMulti v-if="backendPermissions?.allowSendRequestPQ"-->
<!--                        :loading="locState.emailModalOpeningMulti == 'header'"-->
<!--                        :emails-data="getSendEmails"-->
<!--                        @click="()=>showSendEmailModal(null, 'header')"-->
<!--                    />-->
<!--                </div>-->
<!--            </teleport>-->
<!--        </template>-->
        <PartialTabsCurrentSelectedRequest
            :requests="requests"
            :currentActiveRequestTab="currentActiveRequestTab"
            @changeActiveRequestTab="$emit('changeActiveRequestTab', $event)"
            :pqs="pqs">
            <template #nav-right>
                <div class="pq-flights-actions d-flex align-items-center ms-auto gap-2 ">
                    <ElementDropdownAddPq v-if="backendPermissions?.allowCreateRequestPQ"/>
                    <ElementSendPqMulti  v-if="backendPermissions?.allowSendRequestPQ"
                        :loading="locState.emailModalOpeningMulti === 'tabs'"
                        :emails-data="getSendEmails"
                        @click="()=>showSendEmailModal(null, 'tabs')"
                    />
                    <ElementDropdownNextActions/>
                </div>
</template>
        </PartialTabsCurrentSelectedRequest>
        <div class="pt-4 justify-content-center d-flex" v-if="currentActiveRequestPqs?.length  == 0">
<!--            <ElementDropdownAddPq v-if="backendPermissions?.allowCreateRequestPQ"/>-->
        </div>
        <acms-v-tabs v-else
                     class="pq-manager"
                     v-bind="PQ_MANAGER_TABS_CONFIG"
                     :nav-quantity="{
                            'pqs' : currentActiveRequestAgentPqsArray?.length ?? 0,
                            'all-pqs' : currentActiveRequestPqs?.length ?? 0,
                            'hidden-pqs' : '0',
                            'tickets' : 3,
                       }"
        >
            <template #nav-right>
                <div class="ms-auto d-flex flex-wrap">
<!--                    <div class="pq-filter-select  me-3">-->
<!--                        <acms-v-select-->
<!--                            placeholder="Filter by"-->
<!--                            v-model="locState.filterPqsBy"-->
<!--                            multi-->
<!--                            :options="[-->
<!--                                {id:1, title: 'Hidden', quantity: getFilterOptionQnt.hidden.toString()},-->
<!--                                {id:2, title: 'Sent', quantity: getFilterOptionQnt.sent.toString()},-->
<!--                                // {id:3, title: 'Supervisor', quantity: '2'},-->
<!--                                // {id:4, title: 'Expert', quantity: '2'},-->
<!--                            ]"-->
<!--                            :toggle-key-title="()=>'Filter by'"-->
<!--                        >-->
<!--                        </acms-v-select>-->
<!--                    </div>-->

                    <v-chip-group
                        v-model="locState.filterPqsBy"
                        column
                        multiple
                        filter
                        variant="outlined"
                        class="text-blue-darken-3"
                        selected-class="btn-primary"
                    >
                        <v-chip
                            v-for="option in options"
                            :key="option.id"
                            :value="option"

                        >
                            Show {{ option.title }} ({{ option.quantity }})
                        </v-chip>
                    </v-chip-group>
                    <acms-v-switcher
                        title="Simple PQ"
                        v-model="locState.simpleView"
                    />
                </div>
            </template>
            <template #tab-pqs>
                <div class="pq-tables-list">
                    <template v-for="(pqItemRow) in getFilteredCurrentActiveRequestPqsAgent">
                        <div class="pq-tables-item">
                            <div class="pq-tables-item-content d-flex">
                                <div class="pq-tables-item-content-inner">
                                    <PartialPriceQuotesTables
                                        :data="pqItemRow.data"
                                        :simple-view="locState.simpleView"
                                    />
                                </div>
                            </div>
                            <div class="pq-tables-item-actions gap-3 d-flex justify-content-end mt-4">
                                <template v-if="pqItemRow.data.add_button">
                                    <acms-v-btn :title="'Add ' + pqItemRow.data.add_button"
                                                v-if="backendPermissions?.allowCreateRequestPNRPQ"
                                                class="btn-sm btn-dark"
                                                @click="addPnrModal.modalShow = true"
                                    />
                                </template>
                                <ElementDropdownMoreOptions
                                    @select="(e)=>moreOptionsSelectHandle(e, pqItemRow.data)"
                                    :additional-option="getAdditionalOptionForMoreDropdown(pqItemRow.data)"
                                    :pqHiddenStatus="pqItemRow.data.hidden"
                                />
                                <acms-v-btn title="Send" class="btn-sm"
                                            v-if="backendPermissions?.allowSendRequestPQ"
                                            :class="locState.emailModalOpeningId == pqItemRow.data.id ? 'btn-primary' : 'btn-outline-primary'"
                                            :submitting="locState.emailModalOpeningId == pqItemRow.data.id"
                                            @click="()=>showSendEmailModal(pqItemRow.data.id)"/>
                                <div class="pq-tables-item-content-checkbox flex-column d-flex ms-3" style="align-self: center">
                                    <acms-v-switcher
                                        type-checkbox
                                        :model-value="Object.keys(locState.pqRowsChecked).includes(pqItemRow.data.id)"
                                        @update:model-value="(e)=>selectPqRowHandle(e, pqItemRow.data.id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <template #tab-all-pqs>
                <div class="pq-tables-list">
                    <template v-for="(pqItemRow) in getFilteredCurrentActiveRequestPqsPqs">
                        <div class="pq-tables-item">
                            <div class="pq-tables-item-content  d-flex ">
                                <div class="pq-tables-item-content-inner ">
                                    <PartialPriceQuotesTables
                                        :data="pqItemRow.data"
                                        :simple-view="locState.simpleView"
                                    />
                                </div>
                                <!--                                <div class="pq-tables-item-content-checkbox flex-column d-flex  ms-3">-->
                                <!--                                    <acms-v-switcher-->
                                <!--                                        type-checkbox-->
                                <!--                                        :model-value="Object.keys(locState.pqRowsChecked).includes(pqItemRow.data.id)"-->
                                <!--                                        @update:model-value="(e)=>selectPqRowHandle(e, pqItemRow.data.id)"-->
                                <!--                                    />-->
                                <!--                                </div>-->
                            </div>
                            <div class="pq-tables-item-actions gap-3 d-flex justify-content-end mt-4">
                                <template v-if="pqItemRow.data.add_button">
                                    <acms-v-btn :title="'Add ' + pqItemRow.data.add_button"
                                                v-if="backendPermissions?.allowCreateRequestPNRPQ"
                                                class="btn-sm btn-dark"
                                                @click="addPnrModal.modalShow = true"
                                    />
                                </template>

                                <ElementDropdownMoreOptions
                                    @select="(e)=>moreOptionsSelectHandle(e, pqItemRow.data)"
                                    :additional-option="getAdditionalOptionForMoreDropdown(pqItemRow.data)"
                                    :pqHiddenStatus="pqItemRow.data.hidden"
                                />
                                <acms-v-btn title="Send" class="btn-sm"
                                            v-if="backendPermissions?.allowSendRequestPQ"
                                            :class="locState.emailModalOpeningId == pqItemRow.data.id ? 'btn-primary' : 'btn-outline-primary'"
                                            :submitting="locState.emailModalOpeningId == pqItemRow.data.id"
                                            @click="()=>showSendEmailModal(pqItemRow.data.id)"/>
                                <div class="pq-tables-item-content-checkbox flex-column d-flex  ms-3" style="align-self: center">
                                    <acms-v-switcher
                                        type-checkbox
                                        :model-value="Object.keys(locState.pqRowsChecked).includes(pqItemRow.data.id)"
                                        @update:model-value="(e)=>selectPqRowHandle(e, pqItemRow.data.id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <template #tab-exchange-pqs>
                Exchange PQ’s
            </template>
            <template #tab-refund-pqs>
                Refund PQ’s
            </template>
            <template #tab-tickets>
                Tickets PQ’s
            </template>
        </acms-v-tabs>

        <ModalCreateCommonPq
            v-if="commonPQModalShow"
            :clientId="clientId"
            :activeRequest="currentActiveRequestItem"
        />
        <!--  duplicate mode    -->
        <ModalCreateCommonPq
            v-if="locState.modalDuplicatePqShow"
            :clientId="clientId"
            :activeRequest="currentActiveRequestItem"
            :duplicate-from-pq="locState.modalDuplicatePqData"
            mode-duplicate
            :show="locState.modalDuplicatePqShow"
            @hide="hideModalDuplicatePqHandle"

        />

        <ModalAddPnr
            :show="addPnrModal.modalShow"
            @hide="addPnrModal.modalShow = false"
            v-model:data="addPnrModal.data"
        />
        <ModalSendEmails v-if="locState.emailModalShow"
            v-model:show="locState.emailModalShow"
            :dataForForm="locState.emailModalData"
            :currentActiveRequestItem = "currentActiveRequestItem"
            @clearEmailData="emailModalClearData"
        />
        <ModalAddOrChangeLabel
            v-model:show="locState.modalLabelShow"
            :modelValue="locState.modalLabelData"
            @update:modelValue="changeLabelForPqHandle"
        />
        <ModalViewDump
            v-model:show="locState.modalViewDumpShow"
            :dumpCode="locState.modalViewDumpCode"
        />

    </div>
</template>

<script>export default {name: 'PartialPriceQuotes',};</script>

<script setup="">
import {storeToRefs} from "pinia";
import PartialPriceQuotesTables from './partials/PartialPriceQuotesTables.vue';
import {ElementDropdownNextActions, ElementDropdownMoreOptions, ElementDropdownAddPq} from "./elements";
import {ModalAddPnr, ModalCreateCommonPq,ModalViewDump,ModalAddOrChangeLabel,ModalSendEmails} from './modals';
import usePartialPriceQuotesComponent from "./PartialPriceQuotes.component";
import {PartialTabsCurrentSelectedRequest} from "@pages/requests/partials";
import {PQ_MANAGER_TABS_CONFIG} from "@pages/requests/partials/PartialPriceQuotes/constants";
import {usePageRequestShowStore} from "@store/pages/page-request-edit";
import ElementSendPqMulti from "./elements/ElementSendPqMulti.vue";
import {useBackendMiddlewarePropsService} from "@services";
import AcmsVBtn from "@ui/Btn.vue";
import AcmsVQuantity from "@ui/FormField/elements/Quantity.vue";
import {computed, ref} from "vue";

const emit = defineEmits([
    'changeActiveRequestTab'
]);
const props = defineProps({
    requests: Array,
    clientId: [String, Number],
    Tab: [String, Number],
    currentActiveRequestItem: Object,
    currentActiveRequestPqs: Array,
    currentActiveRequestAgentPqsArray: Array,
    pqs: Object,
});
console.log('test', props.currentActiveRequestAgentPqsArray)

const pageRequestShowStore = usePageRequestShowStore()
const {revenuePQModalShow, awardPQModalShow, commonPQModalShow} = storeToRefs(pageRequestShowStore)
const {backendPermissions} = useBackendMiddlewarePropsService()
const {
    locState, getSendEmails,
    showSendEmailModal, selectPqRowHandle,
    emailModalClearData,
    addPnrModal,
    changeLabelForPqHandle,
    moreOptionsSelectHandle,
    getAdditionalOptionForMoreDropdown,
    hideModalDuplicatePqHandle,
    getFilteredCurrentActiveRequestPqsAgent,
    getFilterOptionQnt,
    getFilteredCurrentActiveRequestPqsPqs,
} = usePartialPriceQuotesComponent({props})

const options = ref([
    { id: 1, title: 'Hidden', quantity: computed(() => getFilterOptionQnt.value.hidden.toString()) },
    { id: 2, title: 'Sent', quantity: computed(() => getFilterOptionQnt.value.sent.toString()) },
    // Добавьте другие опции по мере необходимости
])
const toggleFilter = (filter) => {;
    // Убедимся, что locState.filterPqsBy существует или инициализируем его пустым массивом
    if (!locState.filterPqsBy) {
        locState.filterPqsBy = [];
    }

    const index = locState.filterPqsBy.findIndex(item => item.id === filter.id);

    if (index !== -1) {
        locState.filterPqsBy.splice(index, 1);
    } else {
        locState.filterPqsBy.push(filter);
    }
}

</script>
<style lang="scss">
@import "styles";
</style>
