<template>
    <acms-v-tabs
        :items="getTabsItems"
        class="requests-tabs-block"
        content-class="p-0"
        navWrapClass="bg-white"
        @beforeChangeTab="$emit('changeActiveRequestTab', $event)  "
        parentControl
        :active="currentActiveRequestTab"
    >
        <template #nav-right>
            <div style="display: flex; flex-direction: row">
                <a class="btn-grad-kayak acms-v-btn btn btn-sm me-2"  :href="currentKayakString"  target="_blank" rel="noopener noreferrer">Kayak <img  width="20" height="20" src="/media/external/link.svg" style="filter: invert(100%)" alt="link-icon"></a>
                <a class="btn-grad-skyscanner acms-v-btn btn btn-sm me-2" :href="currentSkyScannerString" target="_blank" rel="noopener noreferrer">Skyscanner   <img  width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>
<!--                <button>SeatGuru</button>-->
<!--                <button class="btn-grad-kiwi">Kiwi</button>-->
            </div>
            <slot name="nav-right"></slot>
        </template>
        <template #nav-btn="{item}">
            <div class="d-flex  align-items-start">
                <div class="d-flex align-items-start  flex-column me-2">
                    <div class="tabs-tab-title d-flex">
                        <span>{{ item?.type }}:</span>

                        <div style="margin-left: 2px;" class="d-flex">
                            <template v-if="item?.isMcType">
                                    <span v-tooltip="item.mcTypeContent.tooltip">
                                        {{ item.mcTypeContent.firstAndLastLeg }}
                                    </span>
                            </template>
                            <template v-else>
                                <template v-for="item in item.legs">
                                    <span v-if="item == '*'" style="width: 5px"> </span>
                                    <span v-else>{{ item }}</span>
                                </template>
                            </template>
                        </div>
                    </div>
                    <div class="text-uppercase  f-12 text-start">
                        <template v-for="(itemDate,index) in item.dates">
                            <br v-if="itemDate == '*' && item.dates?.length == 3">
                            <template v-else>
                                <br v-if="itemDate == '*'">
                                <span class="text-gray fw-medium" v-else >{{ itemDate }}</span>
                            </template>

                        </template>
                    </div>
                </div>
                <div class="circle-quantity" v-if="item?.quantity">{{ item.quantity }}</div>
            </div>

        </template>
        <template>
        </template>
    </acms-v-tabs>
</template>
<script>
export default {
    name: "PartialTabsCurrentSelectedRequest",
}
</script>

<script setup="">
import {computed, onMounted} from "vue";
import {find} from "lodash";
const props = defineProps({
    requests: Array,
    currentActiveRequestTab: [String, Number],
    pqs: Object,
})
const emit = defineEmits(['changeActiveRequestTab', ''])

const getTabsItems = computed(() => {

    const getFirstAndLastLeg = (bool, legs = []) => {
        if (bool) {
            const legsSeparated = legs.join('')
            const legsArray = legsSeparated.split('*')
            const firstItem = legsArray.at(0)
            const lastItem = legsArray.at(-1)

            const firstFrom = firstItem.split(('-'))?.at(0) ?? ''
            const lastTo = lastItem.split(('-'))?.at(-1) ?? ''

            return {
                tooltip: legsSeparated.replaceAll('*', ' '),
                firstAndLastLeg: [firstFrom, lastTo].join('-'),
            }
        }
        return false
    }
    return props.requests.reduce((acc, item) => {
        const {dates = null, legs = null, type = null} = item?.pq_tab_details
        acc[item.id] = {
            id: item.id,
            type,
            legs,
            dates,
            quantity: props.pqs?.[item.id]?.length ?? 0,
            isMcType: type.toLowerCase() === 'mc', //
            mcTypeContent: getFirstAndLastLeg(type.toLowerCase() === 'mc', legs)
        }
        return acc
    }, {})

})

const cabin = (cabinClass) => {
    switch (cabinClass) {
        case 1:
            return 'business';
        case 2:
            return '';
        case 3:
            return 'first';
        case 4:
            return 'premium';
        default:
            return 'business';
    }
}

const firstTabKey = computed(() => {
    return props.requests[0]?.id
})

const currentKayakString  = computed(() => {
    let request;
    if (props.currentActiveRequestTab){
        request = props.requests.find((request) => request.id === props.currentActiveRequestTab);
    } else {
        request = props.requests[0];

    }
    const cabinClass = cabin(request.cabin_class_id);
    switch (request.pq_tab_details?.type) {
        case 'RT' : return  `https://www.kayak.com/flights/${request.pq_tab_details.legs[0]}-${request.pq_tab_details.legs[2]}/${request.flight_legs[0].flight_date_from}/${request.flight_legs[1].flight_date_from}/${cabinClass}?sort=price_a`;
        case 'OW' : return `https://www.kayak.com/flights/${request.pq_tab_details.legs[0]}-${request.pq_tab_details.legs[2]}/${request.flight_legs[0].flight_date_from}/${cabinClass}?sort=bestflight_a`;
        default : return  'https://www.kayak.com'
    }
})



const currentSkyScannerString  = computed(() => {
    let request;
    if (props.currentActiveRequestTab){
        request = props.requests.find((request) => request.id === props.currentActiveRequestTab);
    } else {
        request = props.requests[0];

    }
    const cabinClass = cabin(request.cabin_class_id);
    switch (request.pq_tab_details?.type) {
         case 'RT' : return  `https://www.skyscanner.com/transport/flights/${request.pq_tab_details.legs[0]}/${request.pq_tab_details.legs[2]}/${request.flight_legs[0].flight_date_from.replace(/-/g, '')}/${request.flight_legs[1].flight_date_from.replace(/-/g, '')}/?adultsv2=1&cabinclass=${cabinClass}&childrenv2=&currency=USD&inboundaltsenabled=false&locale=en-GB&market=US&outboundaltsenabled=false&preferdirects=false&ref=home&rtn=1`;
        case 'OW' : return  `https://www.skyscanner.com/transport/flights/${request.pq_tab_details.legs[0]}/${request.pq_tab_details.legs[2]}/${request.flight_legs[0].flight_date_from.replace(/-/g, '')}/?adultsv2=1&cabinclass=${cabinClass}&childrenv2=&currency=USD&inboundaltsenabled=false&locale=en-GB&market=US&outboundaltsenabled=false&preferdirects=false&ref=home&rtn=1`;
        default : return  'https://www.skyscanner.com'
    }
})
onMounted(() => {
    if (!props.currentActiveRequestTab) {
        emit('changeActiveRequestTab', firstTabKey.value)
    }
})
</script>

<style lang="scss">
.btn-grad-kayak {
    background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3.2px;
    display: block;
    height: 30px;
    align-self: center;
}

.btn-grad-kayak:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.btn-grad-skyscanner {
    background-image: linear-gradient(to right, #457fca 0%, #5691c8  51%, #457fca  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3.2px;
    display: block;
    height: 30px;

    align-self: center;
}

.btn-grad-skyscanner:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}



</style>
