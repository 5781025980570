export const PQ_MANAGER_TABS_CONFIG = {
    'nav-theme': "alt",
    'nav-wrap-class': "mb-4",
    'items': {
        'pqs': 'PQ’s',
        'all-pqs': 'All PQ’s',
        'exchange-pqs': 'Exchange PQ’s',
        'refund-pqs': 'Refund PQ’s',
        'tickets': 'Tickets',
    },
    'items-content-class': {
        'pqs': 'p-0'
    },
}


export const PQ_ROW_MORE_OPTIONS = {
    duplicate:'Duplicate',
    // exchange:'Exchange' ,
    viewDump:'View Dump',
    // requestPNR:'Request PNR',
    hidePQ:'Hide PQ',

}

export const PQ_ROW_MORE_OPTION_LABEL = {
    addLabel:'Add label',
    changeLabel:'Change label'
}


export const PQ_LABELS_WITH_ICONS_OPTIONS = [
    {
        title: 'None',
        id: 1,
    },
    {
        title: 'Special Deal',
        id: 2,
        icon: 'star',
        color: '#65A30D'
    },
    {
        title: 'Limited Offer',
        id: 3,
        icon: 'hourglass-half',
        color: '#DC2626'
    },
    {
        title: 'Best Overall',
        id: 4,
        icon: 'thumbs-up',
        color: '#EA580C'
    },
    {
        title: 'Best Value',
        id: 5,
        icon: 'money',
        color: '#6C757D'
    },
    {
        title: 'Shortest Flight',
        id: 6,
        icon: 'time',
        color: '#0891B2'
    }
]
