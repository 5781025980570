import {createPopper} from '@popperjs/core';
import $ from 'jquery';
import debounce from "@libs/debounce";
import httpClient from "@services/HttpService";

const PLACEMENTS = ['top', 'left', 'right', 'bottom'];
const TOOLTIP_ATTRIBUTE_NAME = 'data-v-tooltip'

const tooltipMethods = {
    getContent(elTooltip) {
        const {params, modifiers} = elTooltip
        const html = params?.html
        const list = params?.list
        let result = params.text
        if (html) {
            result = html
        }
        if (list) {
            result = list?.reduce((acc, item) => {
                acc += `<div>${item}</div>`;
                return acc
            }, '') ?? 'is not array'
        }
        return result
    },
    getTemplate(elTooltip) {
        return `<div class="acms-vd-tooltip" style="max-width: 1000px"  role="tooltip">
                <div class="acms-vd-tooltip-content" >
                  <pre>${this.getContent(elTooltip)}</pre>
                </div>
        <div class="acms-vd-tooltip-arrow"
             data-popper-arrow></div>
        </div>`;
    },
    create(elTooltip) {
        const el = $(this.getTemplate(elTooltip));
        $('body').append(el);
        return el;
    },

    configure(el, binding, vnode) {
        const vm = vnode.context;
        let params = binding.value;
        const modifiers = binding.modifiers;
        const paramsIsObject = typeof params != 'string';

        el.tooltip = {
            element: null,
            innerElements:{content:null},
            position: null,
            state: {text: null},
            params: {},
            modifiers: {}
        }

        el.tooltip.params = {
            placement: 'top',
            text: typeof params !== 'object' ? params : ''
        }

        el.setAttribute(TOOLTIP_ATTRIBUTE_NAME, true)

        if (Object.keys(modifiers).length > 0) {
            const [top, ...restPlacements] = PLACEMENTS;
            restPlacements.forEach(item => {
                if (modifiers?.[item]) {
                    el.tooltip.params.placement = item
                }
            })

        }

        if (paramsIsObject) {
            el.tooltip.params = {
                ...el.tooltip.params,
                ...params
            };
        }

        el.tooltip.modifiers = modifiers
        //todo check if need
        if (el.tooltip.element) {
            el.tooltip.innerElements.content = el.tooltip.element?.querySelector('.acms-vd-tooltip-content');
            el.tooltip.innerElements.content.innerHTML = tooltipMethods.getContent(el.tooltip)
            el.tooltip.element.classList.add('show');
            el.tooltip.position?.update()
        }

        el.mouseleaveEventHandler = function (event) {
            if (el?.tooltip?.element) {
                if (el.tooltip.modifiers?.always) {
                    el.tooltip.element.classList.remove('show');
                } else {
                    el.tooltip.element.remove()

                    el.tooltip.element = null
                    el.tooltip.position = null
                    el.tooltip.innerElements.content = null
                }
            }
        };

        if (!el.eventHandlersObject) {
            el.eventHandlersObject = {}
            setTimeout(() => {
                el.eventHandlersObject.mouseleave = el.addEventListener('mouseleave', el.mouseleaveEventHandler);
            }, 0);
        }
    }

};

const initTooltipListeners = ({clickEventHandle, mouseoverEventHandle}) => {

    const clickListener = () => {
        document
            .querySelectorAll('.acms-vd-tooltip')
            .forEach(item => item.classList.remove('show'))
    }
    const mouseoverListener = (e) => {
        e.preventDefault();
        const el = e.target
        const hasTooltip = !!el?.getAttribute(TOOLTIP_ATTRIBUTE_NAME)

        const tooltipsElements = document.body.querySelectorAll('.acms-vd-tooltip.show')
        if (tooltipsElements.length > 0) {
            tooltipsElements?.forEach(item => item?.classList.remove('show'))
        }

        if (!hasTooltip) {
            return
        }

        const isTooltipCreated = el?.tooltip?.element
        if (isTooltipCreated) {
            el.tooltip.element.classList.add('show');
            el.tooltip.position?.update()
        } else {

            const tooltipEl = tooltipMethods.create(el.tooltip);

            el.tooltip.element = tooltipEl[0];

            el.tooltip.innerElements.content = el.tooltip.element?.querySelector('.acms-vd-tooltip-content');

            el.tooltip.position = createPopper(el, el.tooltip.element, {
                placement: el.tooltip.params.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            });
            el.tooltip.element.classList.add('show');

            const {asyncRequest,} = el.tooltip.modifiers
            const {requestUrl, requestResponseFun, requestType = 'get', requestParams= null} = el.tooltip.params

            if (asyncRequest && requestUrl) {
                if( el.tooltip.state?.text){
                    el.tooltip.innerElements.content.innerHTML = el.tooltip.state.text
                }else{
                    el.tooltip.innerElements.content.classList.add('is-loading')
                    el.tooltip.innerElements.content.innerHTML = 'loading...'
                    httpClient[requestType](requestUrl,requestParams).then(result => {
                        if (requestResponseFun) {
                            el.tooltip.innerElements.content?.classList.remove('is-loading')
                            el.tooltip.state.text = requestResponseFun?.(result.data)
                            if(el.tooltip.innerElements.content?.innerHTML){
                                el.tooltip.innerElements.content.innerHTML = requestResponseFun?.(result.data)
                            }

                            el.tooltip.position?.update()
                        }
                    })
                }


            }
            el.tooltip.position?.update()
        }

    }
    clickEventHandle?.(clickListener)
    mouseoverEventHandle?.(mouseoverListener)

}

export default function ({clickEventHandle, mouseoverEventHandle}) {

    initTooltipListeners({clickEventHandle, mouseoverEventHandle})

    return {
        mounted(el, binding, vnode) {
            tooltipMethods.configure(el, binding, vnode)
        },
        updated(el, binding, vnode) {
            tooltipMethods.configure(el, binding, vnode)
        },
        beforeUnmount(el) {
            el.removeEventListener('mouseleave', el.mouseleaveEventHandler);
        },
    };


}
