export const modelPqRevenue = {
    code: null,
    pcc_code: null,
    pcc_code_id: null,
    fare_type_id: null,
    baggage_qnt: null,
    price_net: {
        // children: null, adults: null, infants: null
    },
    price_sell: {
        // children: null, adults: null, infants: null
    },
    remark_int: null,
    remark_ext: null,
}

export const MODEL_PQ_NOT_REQUIRED_KEYS = ['remark_int', 'remark_ext']
export const MODEL_PQ_INTEGER_KEYS = ['baggage_qnt']

export const MODEL_PQ_REVENUE_KEYS = Object.keys(modelPqRevenue)
export const MODEL_PQ_REVENUE_OBJECTS_KEYS = ['price_net', 'price_sell']
export const MODEL_PQ_REVENUE_PCC_KEYS = ['pcc_code', 'pcc_code_id']
export const MODEL_PQ_PASSENGERS_TYPES = ['children', 'adults', 'infants']

export const modelPqAwardFirstStep = {
    code: null,
    mileage_id: null,
    baggage_qnt: null,

    revenue: false,

    pcc_code: null,
    pcc_code_id: null,
    fare_type_id: null,
}

export const modelPqAwardSecondStep = {
    prices: [],
    sell_prices: null,
    revenue_prices: null,
    pq_rows_selected_programs: {},
    remark_int: null,
    remark_ext: null,

}

export const MODEL_PQ_AWARD_TAXES_LABELS = ['Miles', 'Taxes', 'Net'];
export const MODEL_PQ_AWARD_TAXES_KEYS = ['miles', 'taxes', 'net'];

export const modelCommonPqFirstStep = {
    code: null,
}

export const modelCommonPqSecondStep = {
    additional_data: {},
    additional_data_baggage: {},
    prices: [
        // {
        //     type: 'revenue',
        //     data: {
        //         // child, adult,
        //     }
        // },
        // {
        //     type: 'airline',
        //     airline: null,
        //     airline_id: null,
        //     data: {
        //         //   adults:   {
        //         //         miles: null,
        //         //         taxes: null,
        //         //         net: null,
        //         //     }
        //     }
        // }
    ],

    sell_prices: null,
    total_net_prices: null,
    remark_int: null,
    remark_ext: null,
}

export const MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_OBJECT = {
    fare_type: null,
    is_real: 'Real',
    ticket: 1,
    type: 1,
    mileage: null,
    pcc: null,
}

export const MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_BAGGAGE = {
    baggage_qnt: null,
}

export const programPccObjKeys = {
    'Revenue': 'pcc',
    'Award': 'mileage',
}

export const MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_KEY = 'additional_data'
export const MODEL_PQ_COMMON_SECOND_STEP_TOTAL_PRICES_KEYS = ['sell_prices', 'total_net_prices']
export const MODEL_PQ_COMMON_SECOND_STEP_KEYS = Object.keys(modelCommonPqSecondStep)
export const MODEL_PQ_COMMON_SECOND_STEP_PRICES_ARRAY_KEY = 'prices'

export const parsedDataExample = {
    "gds": "Amadeus",
    "pnr": null,
    "pcc": "SFO1S2396",
    "flightLegs": [
        {
            "order": "1",
            "airline": "5F",
            "flightNumber": " 615",
            "bookingClass": "Y",
            "departureDate": "20SEP",
            "departureDayOfTheWeek": "3",
            "from": "KIV",
            "to": "MUC",
            "status": "DK",
            "quantity": "1",
            "timeFrom": "1330",
            "timeTo": "1500",
            "arrivalDate": "20SEP"
        },
        {
            "order": "2",
            "airline": "UA",
            "flightNumber": "9255",
            "bookingClass": "Y",
            "departureDate": "20SEP",
            "departureDayOfTheWeek": "3",
            "from": "MUC",
            "to": "EWR",
            "status": "DK",
            "quantity": "1",
            "timeFrom": "1545",
            "timeTo": "1845",
            "arrivalDate": "20SEP"
        },
        {
            "order": "3",
            "airline": "TK",
            "flightNumber": " 004",
            "bookingClass": "C",
            "departureDate": "29SEP",
            "departureDayOfTheWeek": "5",
            "from": "JFK",
            "to": "IST",
            "status": "DK",
            "quantity": "1",
            "timeFrom": "1250",
            "timeTo": "0540",
            "arrivalDate": "30SEP"
        },
        {
            "order": "4",
            "airline": "TK",
            "flightNumber": " 269",
            "bookingClass": "C",
            "departureDate": "30SEP",
            "departureDayOfTheWeek": "6",
            "from": "IST",
            "to": "KIV",
            "status": "DK",
            "quantity": "1",
            "timeFrom": "0730",
            "timeTo": "0900",
            "arrivalDate": "30SEP"
        }
    ],
    "passengers": [],
    "recordLocators": []
}

export const codeForParseExample = '"/$--- MSC SFP ---\n' +
    'RP/SFO1S2396/\n' +
    '  1  5F 615 Y 20SEP 3 KIVMUC DK1  1330 1500  20SEP  E  0 320\n' +
    '     INT\n' +
    '  2  UA9255 Y 20SEP 3 MUCEWR DK1  1545 1845  20SEP  E  0 346 M\n' +
    '     OPERATED BY LUFTHANSA\n' +
    '     SEE RTSVC\n' +
    '  3  TK 004 C 29SEP 5*JFKIST DK1  1250 0540  30SEP  E  0 333 M\n' +
    '     SEE RTSVC\n' +
    '  4  TK 269 C 30SEP 6*ISTKIV DK1  0730 0900  30SEP  E  0 73H M\n' +
    '     SEE RTSVC\n' +
    '  5 RM NOTIFY PASSENGER PRIOR TO TICKET PURCHASE & CHECK-IN:\n' +
    '       FEDERAL LAWS FORBID THE CARRIAGE OF HAZARDOUS MATERIALS -\n' +
    '       GGAMAUSHAZ/S2-3"'
