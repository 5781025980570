<template>
    <div class="box-client-overview">
        <acms-v-card title="Client overview">
            <template #header-right>
                <BoxClientConnectionActions
                    phone-count="1"
                    email-count="1"
                />
            </template>
            <template #default>
                <div class="client-overview-info">
                    <div class="client-overview-info-row">
                        <div class="row">
                            <div class="col-6">
                                <template v-for="item in getInfo.left" :key="item.title">
                                    <SimpleClientInfoBlock
                                        :style=" item.content ? 'padding-bottom:12px' : ''"
                                        :item="item"
                                    />
                                </template>
                            </div>
                            <div class="col-6">
                                <template v-for="item in getInfo.right" :key="item.title">
                                    <SimpleClientInfoBlock
                                        :style=" item.content ? 'padding-bottom:12px' : ''"
                                        :item="item"
                                    />
                                </template>
                            </div>
                        </div>
                    </div>
                    <template v-for="[blockKey, blockArray] in Object.entries(clientAbout)" :key="blockKey">
                        <div class="client-overview-info-row">
                            <div class="row" :class="{'gap-row-3': blockKey != 'info'}"
                                 :style="blockKey == 'info' ? 'padding-bottom:8px' : ''">
                                <template v-for="(item, key) in blockArray" :key="key">
                                    <SimpleClientInfoBlock
                                        :class="[item?.full  ? 'col-12' : 'col-6', item.order ? `order-${item.order}` : '' ]"
                                        :style="blockKey == 'info' && item?.content ? 'padding-bottom:12px' : ''"
                                        :item="item">
                                        <template v-if="isObject(item.content)">
                                            <div class="d-flex">
                                                <div class="me-auto" :class="item.content?.class">
                                                    <template v-if="item.canToggleObscure">
                                                        <template v-if="item.content.obscure">
                                                            {{item.content.obscureValue}}
                                                        </template>
                                                        <template v-else>
                                                            <template v-if="item.contact_type === 'phone'">
                                                                <a :href="'tel:'+item.content.originalValue"> {{ item.content.originalValue }}</a>
                                                            </template>
                                                            <template
                                                                v-else>
                                                                {{ item.content.originalValue }}
                                                            </template>

                                                        </template>

                                                    </template>
                                                    <template v-else>
                                                        {{ item.content.text }}
                                                    </template>
                                                </div>
                                                <template v-if="item?.canToggleObscure">
                                                    <acms-v-btn class="p-0 me-3 text-primary-dark" iconSize="20"
                                                                :disabled="item.content?.loading || !!item.content?.originalValue"
                                                                v-tooltip.right="getTooltipRevealsLeft"
                                                                @click="obscureContactHandle(item)"
                                                                :icon="item.content.obscure ? 'eye-off' : 'eye'"/>
                                                </template>
                                            </div>
                                        </template>
                                    </SimpleClientInfoBlock>
                                </template>
                            </div>
                        </div>
                    </template>
                    <!-- New Block for Agents -->
                    <div class="client-details-info-row mt-3">
                        <div class="text-primary-dark fw-bold mb-2">Agents</div>
                        <div v-for="agent in props.updatedData.data.agents" :key="agent.id" class="agent-details d-flex align-items-center">
                            <acms-v-btn class="bg-gray-light p-1 pv-2 d-flex align-items-center justify-content-between" content-class="d-flex">
                                <acms-v-icon name="user" class="p-1 bg-white radius-50 me-1" color="text-gray-darker"/>
                                <span>{{ getFullName(agent) }} </span>
                                <acms-v-icon v-if="backendPermissions.allowUnAssignAgents" name="close" style="padding-top: 4px" class="ms-2 text-danger" @click.stop="confirmRemoveAgent(agent)" />
                            </acms-v-btn>
                        </div>
                    </div>
                </div>
            </template>
        </acms-v-card>
    </div>

    <v-dialog v-model="dialog" max-width="700px">
        <v-card>
            <v-card-title class="headline">Unassignment</v-card-title>
            <v-card-text>Would you like to unassigned agent "{{selectedAgentName}} " from this client?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="dialog = false">Cancel</v-btn>
                <v-btn color="red darken-1"  @click="removeAgent">Unassign</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import {isObject} from 'lodash';
import BoxClientConnectionActions from '@boxes/client/BoxClientConnectionActions/BoxClientConnectionActions.vue';
import {computed, onBeforeMount, ref, watch, watchEffect} from 'vue';
import {
    getHiddenEmail,
    getHiddenPhone,
    getSecurizedEmail,
    getSecurizedPhone,
    getFullName
} from "@/helpers/commonHelpers";
import SimpleClientInfoBlock from "@boxes/client/BoxCardClientOveriview/partials/SimpleClientInfoBlock.vue";
import httpClient from "@services/HttpService";
import {getRoute} from "@plugins/useRoutes";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {useBackendMiddlewarePropsService} from "@services";
import axios from 'axios'; // Add axios for HTTP requests

const props = defineProps({
    generalInfo: Object,
    genderAndContactBy: Object,
    createdAndUpdated: Object,
    contacts: Object,
    bestTimeToCall: String,
    dates: Object,
    updatedData: Object,
});

const AlertsManager = useAlertsManagerStore();
const {backendPermissions, backendContactViewRevealsLeft} = useBackendMiddlewarePropsService();
const clientInfo = ref();
const clientAbout = ref();

const currentRevealsLeft = ref(backendContactViewRevealsLeft.value);
const dialog = ref(false);
const selectedAgent = ref(null);
const selectedAgentName = ref(null); // Add a ref for the agent name

const getTooltipRevealsLeft = computed(() => String(currentRevealsLeft.value) + ' reveals left');

const getInfo = computed(() => {
    const {first_name = null, middle_name = null, last_name = null} = props.generalInfo;
    const {genderAndContactBy, bestTimeToCall} = props;
    return {
        left: [
            {
                title: 'First name',
                content: first_name,
            },
            {
                title: 'Middle name',
                content: middle_name,
            },
            {
                title: 'Last name',
                content: last_name,
            },
        ],
        right: [
            {
                title: 'Gender',
                content: genderAndContactBy?.gender,
            },
            {
                title: 'Contact by',
                content: genderAndContactBy?.contactBy,
            },
            {
                title: 'Best time to call',
                content: bestTimeToCall,
            }
        ]
    };
});

const formatDateToDDMMMYY = (dateStr) => {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const date = new Date(dateStr);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear().toString().substr(-2);

    return `${day < 10 ? '0' + day : day}${months[monthIndex]}${year}`;
};

const getChangesDates = computed(() => {
    const {created, updated} = props.createdAndUpdated;
    return [
        {
            title: 'Created',
            content: formatDateToDDMMMYY(created.date) + ' &nbsp;&nbsp;' + created.time + ' ' + created.type,
        },
        {
            title: 'Updated',
            content: formatDateToDDMMMYY(updated.date) + ' &nbsp;&nbsp;' + updated.time + ' ' + updated.type,
        }
    ];
});

const getMails = computed(() => {
    return props.contacts?.emails?.reduce((acc, item) => {
        const sample = {
            title: ((item?.pivot?.type_id ?? item.id) == 1 ? 'Personal' : 'Shared') + ' email',
            full: true,
            order: 2,
            canToggleObscure: true,
            contact_id: item.id,
            contact_type: 'email',
            content: {
                obscure: true,
                obscureValue: item?.text ? getHiddenEmail(item) : getHiddenEmail(item),
                originalValue: null,
                text: item?.email ?? item.text,
                loading: false
            },
        };
        const isMain = item.pivot?.main ?? item.main;
        if (isMain) {
            sample.order = 1;
            sample.title = sample.title + ' (Main)';
        }

        if (sample.content.text?.length > 0) {
            acc.push(sample);
        }
        return acc;
    }, []);
});

const getPhones = computed(() => {
    return props.contacts?.phone_numbers?.reduce((acc, item) => {
        const sample = {
            title: ((item?.pivot?.type_id ?? item.id) == 1 ? 'Personal' : 'Shared') + ' phone',
            order: 2,
            full: true,
            contact_id: item.id,
            contact_type: 'phone',
            canToggleObscure: getSecurizedPhone(item)?.includes('*'),
            content: {
                obscure: true,
                obscureValue: (item.dial_code ?? item.phoneCode?.code) + ' ' + (item?.text ? getHiddenPhone(item) : getHiddenPhone(item)),
                originalValue: null,
                text: getHiddenPhone(item),
                loading: false
            },
        };
        const isMain = item.pivot?.main ?? item.main;
        if (isMain) {
            sample.title = sample.title + ' (Main)';
            sample.order = 1;
        }
        if (sample.content.text?.length > 0) {
            acc.push(sample);
        }
        return acc;
    }, []);
});

const obscureContactHandle = (item) => {
    const {contact_id, contact_type} = item;
    const client_id = props.updatedData.data.id;
    const sendObject = {contact_id, contact_type, client_id};
    if (!item.content.originalValue) {
        item.content.loading = true;
        httpClient.post(getRoute('clients.contact.get'), sendObject).then(({data}) => {
            if (data?.success) {
                item.content.loading = false;
                item.content.originalValue = data?.data;
                item.content.obscure = false;
                if (data?.count_remain) {
                    currentRevealsLeft.value = data?.count_remain;
                }
            } else if (data?.error) {
                AlertsManager.add(data?.error, 'danger');
            }
        });
    }
};

const getImportant = () => {
    const array = [];
    const status = {
        title: 'Status',
        content: {
            text: 'New prospect',
            class: 'btn-alt-primary badge',
        },
    };
    const revenue = {
        title: 'Revenue',
        content: {
            text: '0,00',
            class: 'fw-bold',
        },
    };
    if (backendPermissions?.allowViewClientStatus) {
        array.push(status);
    }
    array.push(revenue);
    return array;
};

const setClientAbout = () => {
    clientInfo.value = getInfo.value;
    clientAbout.value = {
        changesDates: getChangesDates.value,
        important: getImportant()
    };
    const mails = getMails.value;
    const phones = getPhones.value;
    if (mails?.length > 0) {
        clientAbout.value.mails = mails;
    }
    if (phones?.length > 0) {
        clientAbout.value.phones = phones;
    }
};

watch(() => props.updatedData, () => {
    setClientAbout();
});

watchEffect(() => {
    setClientAbout();
});
onBeforeMount(() => {
    setClientAbout();
});

const confirmRemoveAgent = (agent) => {
    selectedAgent.value = agent;
    selectedAgentName.value = getFullName(agent); // Set the agent name
    dialog.value = true;
};

const removeAgent = () => {
    if (selectedAgent.value) {
        axios.delete(`/clients/${props.updatedData.data.id}/agents/${selectedAgent.value.id}`)
            .then(response => {
                if (response.data.success) {
                    // Успешное удаление агента
                    const index = props.updatedData.data.agents.findIndex(a => a.id === selectedAgent.value.id);
                    if (index !== -1) {
                        props.updatedData.data.agents.splice(index, 1);
                    }
                    // Обновление данных запроса
                    AlertsManager.add('Agent was successfully removed', 'success');
                } else {
                    // Обработка ошибки, если нельзя удалить последнего агента
                    alert(response.data.message);
                }
                dialog.value = false;
                selectedAgent.value = null;
                selectedAgentName.value = null; // Clear the agent name
            })
            .catch(error => {
                // Обработка ошибки
                console.error('Failed to remove agent:', error);
                dialog.value = false;
                selectedAgent.value = null;
                selectedAgentName.value = null; // Clear the agent name
                AlertsManager.add('Cant delete agent', 'danger');
            });
    }
};
</script>

<style lang="scss">
.box-client-overview {
    .client-overview-info-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    .client-overview-info-content {
        font-size: 16px;
        line-height: 20px;
    }

    .client-overview-info-row {
        &:first-child {
            padding-bottom: 6px;
        }

        &:not(:first-child) {
            padding-bottom: 20px;
        }

        &:not(:first-child) {
            border-top: 1px solid #CED4DA;
            padding-top: 20px;
        }
    }

    .client-details-info-row {
        .agent-details {
            margin-bottom: 10px;
        }
    }
}
</style>
