import axios from 'axios'
import {getRoute} from "@plugins/useRoutes";

const httpClient = axios.create({
    withCredentials: false, // false is the default
    timeout: 4000
})

export const HttpService = {

    parsePQCode(data) {
        return httpClient.post(getRoute('pq.parse'), data)
    },

    previewPQEmail(data) {
        return httpClient.post(getRoute('pq.preview'), data)
    }
}

export default httpClient
